import React from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SearchInput } from 'src/features/catalog/SearchInput'
import {
  getAllCategories, getMainBanner 
} from 'src/features/catalog/selectors'
import { Preloader } from 'src/ui/Preloader'

export const HomePage = ({ className = '' }) => {
  const categories = getAllCategories()
  const banner = getMainBanner()

  return (
    <HomePageBox className={className}>
      <SearchRow>
        <StyledSearchInput isPlaceholder />
      </SearchRow>

      {
        (!categories.length || !banner) &&
        <PreloaderBox>
          <StyledPreloader />
        </PreloaderBox>
      }

      {
        !!categories.length && !!banner &&
        <>
          <BannerBox to={`/category/${banner.category.data.id}`}>
            <BannerImg src={banner.image?.data?.attributes?.url} />
            <BannerText>{banner.description}</BannerText>
          </BannerBox>
      
          <CategoriesBox>
            {
              categories.map(category => (
                <CategoryLink
                  key={category.id}
                  to={`/category/${category.id}`}
                >
                  {!!category.attributes.image?.data?.attributes?.url && <CategoryImg src={category.attributes.image?.data?.attributes?.url} />}

                  <CategoryTextBox>
                    <CategoryText>{category.attributes.name}</CategoryText>
                    {!!category.attributes?.description && <CategoryDescription>{category.attributes?.description}</CategoryDescription>}
                  </CategoryTextBox>
              
                </CategoryLink>
              ))
            }
          </CategoriesBox>
        </>
      }
      

      
    </HomePageBox>
  )
}

const HomePageBox = styled.div`
  padding: 16px 0 90px;
  position: relative;
`

const BannerBox = styled(Link)`
  margin-top: 23px;
  width: 100%;
  min-height: 180px;
  position: relative;
  display: block;
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`

const BannerImg = styled.img`
  width: 100%;
  height: auto;
`

const BannerText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.secondary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`


const CategoriesBox = styled.div`
  margin-top: 24px;
  padding: 0 16px;
`

const CategoryLink = styled(Link)`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.29);

  & ~ & {
    margin-top: 8px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const CategoryImg = styled.img`
  margin-right: 24px;
  width: 64px;
  height: 64px;
  object-fit: contain;
  border-radius: 50%;
`

const CategoryTextBox = styled.div`
`

const CategoryText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.main};
`

const CategoryDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.text.quaternary};
`

const SearchRow = styled.div`
  width: 100%;
  padding: 0 16px;
`

const StyledSearchInput = styled(SearchInput)`
  
`

const PreloaderBox = styled.div`
  height: calc(100vh - 200px);
  position: relative;
`

const StyledPreloader = styled(Preloader)`
`
