import React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

export const BtnFilters = React.memo(({
  className = '', options = [], active, onSelect 
}) => {
  return (
    <BtnFiltersBox className={className}>
      <OptionsBox>
        {options.map(option => (
          <BtnFilter
            key={option.label}
            className={classNames({ active: option.id == active })}
            onClick={() => onSelect(option.id)}
          >
            {option.label}
          </BtnFilter>
        ))}
      </OptionsBox>
    </BtnFiltersBox>
  )
})

const BtnFiltersBox = styled.div`
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
`

const OptionsBox = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`

const BtnFilter = styled.div`
  border: 1px solid #7E7E82;
  border-radius: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  color: ${props => props.theme.text.quaternary};
  cursor: pointer;
  transition: all 0.4s linear;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }

  & ~ & {
    margin-left: 16px;
  }

  &.active {
    background: ${props => props.theme.backgrounds.tertiary};
    color: ${props => props.theme.text.secondary};
    border-color: ${props => props.theme.text.tertiary};
  }
`