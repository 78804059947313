import {
  createAsyncThunk, createSlice 
} from '@reduxjs/toolkit'

import { catalogApi } from '../api'

const initialState = {
  userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 3, 
  deliveryAddress: null,
}

export const fetchUserInfo = createAsyncThunk(
  'app/fetchUserInfo', 
  async (_, { dispatch }) => {
    try {
      const response = await catalogApi.getDeiveryAddress()
  
      if (response.data) {
        dispatch(appActions.setDeliveryAddress(response.data?.attributes))
      }
    } catch (error) {
      console.error(error)
    }
  }
)

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload
    }
  },
})

export const appActions = appSlice.actions
export const appReducer = appSlice.reducer