import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import {
  Routes, Route 
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ScrollToTopWhenLocationChanged } from './features/app/ScrollToTopWhenLocationChanged'
import { BasketBtn } from './features/basket/BasketBtn'
import { useBasket } from './features/basket/useBasket'
import {
  fetchCategories, fetchMainBannerInfo 
} from './features/catalog/catalogSlice'
import { BasketPage } from './pages/BasketPage'
import { CatalogPage } from './pages/CatalogPage'
import { HomePage } from './pages/HomePage'
import { ProductPage } from './pages/ProductPage'
import { SearchPage } from './pages/SearchPage'
import { GlobalStyle } from './ui/styled.global'
import {
  DARK_THEME, LIGHT_THEME 
} from './constants/themes'
import { fetchUserInfo } from './features/app/appSlice'


function App() {
  const dispatch = useDispatch()

  const { loadBasket } = useBasket()

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchMainBannerInfo())
    dispatch(fetchUserInfo())

    loadBasket()
  }, [])

  const currentTheme = window.Telegram.WebApp.colorScheme === 'dark' ? DARK_THEME : LIGHT_THEME

  return (
    <ThemeProvider theme={currentTheme}>
      <ScrollToTopWhenLocationChanged>
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/category/:id"
            element={<CatalogPage />}
          />
          <Route
            path="/product/:id"
            element={<ProductPage />}
          />
          <Route
            path="/basket"
            element={<BasketPage />}
          />
          <Route
            path="/search"
            element={<SearchPage />}
          />
        </Routes>

        <BasketBtn />
        <GlobalStyle />
      </ScrollToTopWhenLocationChanged>
    </ThemeProvider>
  )
}

export default App
