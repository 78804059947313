import {
  createSlice, createAsyncThunk 
} from '@reduxjs/toolkit'

import { catalogApi } from '../api'

const initialState = {
  categories: [], 
  mainBanner: null, 
}

export const fetchCategories = createAsyncThunk(
  'catalog/fetchCategories',
  async () => {
    const response = await catalogApi.getCategories()
    
    return response.data
  }
)

export const fetchMainBannerInfo = createAsyncThunk(
  'catalog/fetchMainBanner',
  async () => {
    const response = await catalogApi.getMainBanner()
    
    return response.data?.attributes
  }
)

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCategories:(state, action) => {
      state.categories = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload
    })

    builder.addCase(fetchMainBannerInfo.fulfilled, (state, action) => {
      state.mainBanner = action.payload
    })
  }
})

export const catalogActions = catalogSlice.actions
export const catalogReducer = catalogSlice.reducer