import React from 'react'

import { Link } from 'react-router-dom'
import { Button } from 'src/ui/Button'
import { Counter } from 'src/ui/Counter'
import { extractImgUrl } from 'src/utils/extractors'
import styled from 'styled-components'

import { getBasketProducts } from '../basket/selectors'
import { useBasket } from '../basket/useBasket'

export const ProductCard = React.memo(({
  className = '', data 
}) => {
  const extractedData = {
    id: data.id, ...data.attributes 
  }

  const products = getBasketProducts()

  const {
    addProduct, removeProduct 
  } = useBasket()

  const productCountInBasket = products.find(product => product.id === Number(data.id))?.count || 0

  const handleAddProductClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    
    addProduct(extractedData)
  }

  const handleRemoveProductClick = () => {
    removeProduct(extractedData)
  }

  return (
    <ProductCardBox
      className={className}
      to={`/product/${data.id}`}
    >
      <ImgBox>
        <ProductImg src={extractedData.images?.[0]?.url || extractImgUrl(extractedData.images?.data?.[0])} />
      </ImgBox>

      <Title>
        {extractedData.name}
      </Title>

      <PriceRow>
        <Price>
          {extractedData.price} {extractedData.unit}
        </Price>
        {!!extractedData.old_price &&
        <OldPrice>
          {extractedData.old_price} {extractedData.unit}
        </OldPrice>
        }
      </PriceRow>

      {
        productCountInBasket === 0 &&
          <BusketButton onClick={handleAddProductClick}>
            В корзину
          </BusketButton>
      }

      {
        productCountInBasket !== 0 &&
          <StyledCounter
            count={productCountInBasket}
            onPlus={handleAddProductClick}
            onMinus={handleRemoveProductClick}
          />
      }
    </ProductCardBox>
  )
})

const ProductCardBox = styled(Link)`
  display: flex;
  flex-direction: column;
`

const ImgBox = styled.div`
  width: 100%;
  height: 164px;
  /* background:rgba(118, 118, 128, 0.08); */
  border-radius: 8px;
`

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Title = styled.p`
  margin-top: 12px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: ${props => props.theme.text.quaternary};
`

const PriceRow = styled.div`
  margin-top: auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Price = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.main};
`

const OldPrice = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  text-decoration-line: line-through;
  color: ${props => props.theme.text.quaternary};
`

const BusketButton = styled(Button)`
  width: 100%;
  height: 40px;
  font-size: 16px;
`

const StyledCounter = styled(Counter)`
  height: 40px;
  width: 100%;
`