import React, {
  useState, useEffect
} from 'react'

import { OrderBtn } from 'src/features/basket/BasketBtn'
import { getBasketProducts } from 'src/features/basket/selectors'
import { useBasket } from 'src/features/basket/useBasket'
import { BackButton } from 'src/features/catalog/BackButton'
import { Counter } from 'src/ui/Counter'
import {
  InputGroupIOS, InputIOS
} from 'src/ui/InputIOS'
import { Switch } from 'src/ui/Switch'
import { extractImgUrl } from 'src/utils/extractors'
import styled from 'styled-components'
import { catalogApi } from 'src/features/api'
import {
  getDeliveryAddress, getUserId 
} from 'src/features/app/selectors'
import { CheckboxSelectIOS } from 'src/ui/CheckboxSelectIOS'
import { MIN_ORDER_AMOUNT } from 'src/constants'

import { ReactComponent as TrashIcon } from '@/images/icons/trash.svg'

export const BasketPage = ({ className = '' }) => {
  const products = getBasketProducts()
  const userId = getUserId()
  const deliveryAddress = getDeliveryAddress()

  const [paymentTypes, setPaymentTypes] = useState([])
  const [currentPaymentType, setCurrentPaymentType] = useState(null)

  const [withDelivery, toggleDelivery] = useState(true)
  const [userData, setUserData] = useState({
    name: '',
    phone: ''
  })
  const [deliveryData, setDeliveryData] = useState({
    city: process.env.REACT_APP_SINGLE_CITY || '',
    street: '',
    house: '',
    appartment: '',
    floor: ''
  })

  const [comment, setComment] = useState('')
  const [deliveryInfo, setDeliveryInfo] = useState(null)
  const [requestInProcess, toggleRequestProcess] = useState(false)

  const amount = products.reduce((r, p) => r += p.price * (p.count || 1), 0)
  const discount = products.reduce((r, p) => r += (p.price - (p.old_price || p.price)) * (p.count || 1), 0)

  const {
    addProduct, removeProduct, removeAll
  } = useBasket()

  useEffect(() => {
    if (deliveryAddress?.address) {
      const {
        city, appartment, floor, house, street 
      } = deliveryAddress?.address
      
      setDeliveryData({
        city,
        appartment,
        floor,
        house,
        street
      })
    }

    if (deliveryAddress?.info) {
      const {
        name, phone
      } = deliveryAddress?.info
      
      setUserData({
        name, phone
      })
    }
  }, [deliveryAddress])

  useEffect(async () => {
    const response = await catalogApi.getPaymentsMethods()

    setPaymentTypes(response.data.map(payment => ({
      label: payment.name, value: payment.name, img: payment?.image?.url
    })))

    setCurrentPaymentType(response.data.find(payment => payment.default).name)
  }, [])

  useEffect(async () => {
    const response = await catalogApi.getDeliveryInfo()

    setDeliveryInfo(response.data)
  }, [])

  const handleAddProductClick = (data) => {
    addProduct(data)
  }

  const handleRemoveProductClick = (data, full = false) => {
    removeProduct(data, full)
  }

  const handleDeliveryDataChange = (e, field) => {
    const regexp = /[^0-9-a-zа-яё_\-\/=\s]/gi
    const value = e.target.value
    setDeliveryData(state => ({
      ...state, [field]: value.replace(regexp, '').substring(0, 25)
    }))
  }

  const handleCreateOrder = async () => {
    toggleRequestProcess(true)

    const response = await catalogApi.createOrder({
      data: {
        userId,
        userData,
        deliveryData,
        comment,
        gateway_choice: currentPaymentType
      }
    })

    if (response.results !== 'err') {
      window?.Telegram?.WebApp?.close()
    }

    toggleRequestProcess(false)
  }

  const btnDisabled = !userData.name || !userData.phone || requestInProcess ||
  (withDelivery && (!deliveryData.city || !deliveryData.street || !deliveryData.house)) || amount < MIN_ORDER_AMOUNT

  return (
    <BasketPageBox className={className}>
      <TopRow>
        <BackButton />
      </TopRow>

      <OrderBox>
        <TitleRow>
          <Title>Корзина</Title>

          <RemoveAllBtn onClick={removeAll}>Удалить все</RemoveAllBtn>
        </TitleRow>

        {
          products.map(product => (
            <ProductRow key={product.id}>
              <ProductImgBox>
                <ProductImg src={extractImgUrl(product.images?.data?.[0])} />
              </ProductImgBox>

              <ProductInfo>
                <ProductTitle>{product.name}</ProductTitle>

                <PriceRow>
                  <Price>{product.price} ₽</Price>
                  {!!product.old_price &&
                    <OldPrice>{product.old_price} ₽</OldPrice>
                  }
                </PriceRow>

                <ProductDescription>
                  {product.orderParams}
                </ProductDescription>

                <ControlsRow>
                  <StyledCounter
                    size="small"
                    count={product.count}
                    onPlus={() => handleAddProductClick(product)}
                    onMinus={() => handleRemoveProductClick(product)}
                  />

                  <RemoveProduct onClick={() => handleRemoveProductClick(product, true)}>
                    <StyledTrashIcon />
                    Удалить
                  </RemoveProduct>
                </ControlsRow>
              </ProductInfo>
            </ProductRow>
          ))
        }

        <ProductsInfo>
          <ProductsInfoRow>
            Товаров в заказе ({products.length})

            <ProductsInfoAmount>{amount} ₽</ProductsInfoAmount>
          </ProductsInfoRow>

          <ProductsInfoRow>
            Скидка

            <ProductsInfoAmount>{discount} ₽</ProductsInfoAmount>
          </ProductsInfoRow>
        </ProductsInfo>

        <TotalRow>
          Итого:
          <span>{amount} ₽</span>
        </TotalRow>
      </OrderBox>

      <InputGroupLabel>
        Покупатель
      </InputGroupLabel>
      <StyledInputGroupIOS>
        <InputIOS
          label="Имя"
          value={userData.name}
          onChange={(e) => {
            const regexp = /[^a-zа-яё_\-\/=\s]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              name: value.replace(regexp, '').substring(0, 25)
            }))}}
        />
        <InputIOS
          label="Телефон"
          value={userData.phone}
          mask="+79999999999"
          onChange={(e) => {
            const regexp = /[^0-9]/gi
            const value = e.target.value
            setUserData(state => ({
              ...state,
              phone: '+' + value.replace(regexp, '').substring(0, 11).trim()
            }))}}
        />
      </StyledInputGroupIOS>

      <InputGroupLabel>
        Комментарий
      </InputGroupLabel>
      <StyledInputGroupIOS>
        <Textarea
          placeholder={process.env.REACT_APP_ORDER_COMMENT_PLACEHOLDER || 'Комментарий'}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </StyledInputGroupIOS>

      <DeliveryRow>
        Доставка

        <Switch
          checked={withDelivery}
          onChange={toggleDelivery}
        />
      </DeliveryRow>

      {
        withDelivery &&
        <>
          {
            !!deliveryInfo?.min_delivery_price &&
          <DeliveryRow>
            Минимальная стоимость доставки

            <span>{deliveryInfo?.min_delivery_price} ₽</span>
          </DeliveryRow>
          }
          {deliveryInfo &&
            <DeliveryInfoBox>
              <DeliveryImg src={deliveryInfo?.delivery_info_image?.url} />
              <DeliveryInfo>
                {deliveryInfo?.delivery_info}
              </DeliveryInfo>
            </DeliveryInfoBox>
          }

          <InputGroupLabel>
            Адрес доставки
          </InputGroupLabel>
          <StyledInputGroupIOS>
            <InputIOS
              label="Город"
              value={deliveryData.city}
              disabled={!!process.env.REACT_APP_SINGLE_CITY}
              onChange={(e) => handleDeliveryDataChange(e, 'city')}
            />
            <InputIOS
              label="Улица"
              value={deliveryData.street}
              onChange={(e) => handleDeliveryDataChange(e, 'street')}
            />
            <InputIOS
              label="Дом"
              value={deliveryData.house}
              onChange={(e) => handleDeliveryDataChange(e, 'house')}
            />
            <InputIOS
              label="Квартира"
              value={deliveryData.appartment}
              onChange={(e) => handleDeliveryDataChange(e, 'appartment')}
            />
            <InputIOS
              label="Этаж"
              value={deliveryData.floor}
              onChange={(e) => handleDeliveryDataChange(e, 'floor')}
            />
          </StyledInputGroupIOS>
        </>
      }

      <InputGroupLabel>
        Вид оплаты
      </InputGroupLabel>

      <StyledCheckboxSelectIOS
        checked={currentPaymentType}
        options={paymentTypes}
        onChange={setCurrentPaymentType}
      />

      <Rules>
        Нажимая на кнопку «Сформировать заказ», я принимаю <a href="#">пользовательское соглашение</a> и <a href="#">политику конфиденциальности</a>
      </Rules>

      <OrderBtn
        text={`Оформить заказ - ${amount} ₽`}
        disabled={btnDisabled}
        onClick={handleCreateOrder}
      />
    </BasketPageBox>
  )
}

const BasketPageBox = styled.div`
  background: ${props => props.theme.backgrounds.secondary};
  min-height: 100vh;
  padding: 16px 8px 85px;
`

const OrderBox = styled.div`
  width: 100%;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  padding: 16px;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const ProductRow = styled.div`
  display: flex;

  & ~ & {
    margin-top: 24px;
  }
`

const ProductImgBox = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  background: rgba(118, 118, 128, 0.08);
  border-radius: 4px;
`

const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ProductInfo = styled.div`
  margin-left: 16px;
  width: 100%;
`

const ProductTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const PriceRow = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const OldPrice = styled.p`
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  text-decoration-line: line-through;
  color: ${props => props.theme.text.quaternary};
`

const Price = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const ProductDescription = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: ${props => props.theme.text.quaternary};
`

const TotalRow = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 5px;
  border-top: 0.33px solid rgba(60, 60, 67, 0.29);
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const ProductsInfo = styled.div`
  margin-top: 15px;
  padding: 23px 0 5px;
  border-top: 0.33px solid rgba(60, 60, 67, 0.29);
`

const ProductsInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};

  & ~ & {
    margin-top: 24px;
  }
`

const ProductsInfoAmount = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const TopRow = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledInputGroupIOS = styled(InputGroupIOS)`
  
`

const InputGroupLabel = styled.p`
  margin-top: 28px;
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  color: #636366;
`

const DeliveryRow = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
`

const DeliveryInfoBox = styled.div`
  margin-top: 24px;
  background: ${props => props.theme.backgrounds.main};
  border-radius: 8px;
  padding: 16px;
`

const DeliveryImg = styled.img`
  width: 100%;
`

const DeliveryInfo = styled.p`
  margin-top: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.text.main};
`

const StyledCounter = styled(Counter)`
  width: 100px;
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`

const RemoveAllBtn = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.text.tertiary};
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }
`

const ControlsRow = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RemoveProduct = styled.div`
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: ${props => props.theme.text.main};
`

const StyledTrashIcon = styled(TrashIcon)`
  margin-right: 10px;

  path {
    fill: ${props => props.theme.text.main};
  }
`

const StyledCheckboxSelectIOS = styled(CheckboxSelectIOS)`

`

const Rules = styled.p`
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.main};
  padding: 0 5px;

  a {
    color: ${props => props.theme.text.tertiary};
    text-decoration: underline;
  }
`

const Textarea = styled.textarea`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: ${props => props.theme.inputs.color};
  border: none;
  padding: 10px 15px;
  /* border-top: 0.33px solid rgba(60, 60, 67, 0.29); */
  width: 100%;
  outline: none;
  border-radius: 8px;
  background: ${props => props.theme.backgrounds.main};

  &::placeholder {
    color: ${props => props.theme.inputs.placeholder};
  }
`